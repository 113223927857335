import { VhImage } from "./image";
import { VhLocation } from "./location";
import { alias } from "../../utils/utils";
import { VhPage, getVhUrl } from "../../utils/url-helper";

export enum CheckInMethod {
    FrontDesk = 'Doorman',
    Host = 'Host Check-in',
    Keypad = 'Keypad',
    LockBox = 'Lockbox',
    SmartLock = 'Smartlock',
    Other = 'Other'
}

export interface IAmenityGroup {
    name: string;
    amenities: string[];
}

export class Bed {
    @alias('name')
    public name!: string;

    @alias('quantity')
    public quantity!: number;

    constructor(args: any) {
        if (!args) {
            return;
        }

        const fields: { [key: string]: string } = (<any>Bed)._alias;

        for (const field in fields) {
            if (Object.hasOwn(fields, field)) {
                const _alias = fields[field];
                (this as any)[field] = args[_alias];
                if ((this as any)[field] === undefined) {
                    (this as any)[field] = args[field];
                }
            }
        }
    }
}

export class Room {
    @alias('room_number')
    public roomNumber!: number;

    @alias('room_type')
    public roomType!: string;

    public beds: Bed[] = [];

    constructor(args: any) {
        if (!args) {
            return;
        }

        const fields: { [key: string]: string } = (<any>Room)._alias;

        for (const field in fields) {
            if (Object.hasOwn(fields, field)) {
                const _alias = fields[field];
                (this as any)[field] = args[_alias];
                if ((this as any)[field] === undefined) {
                    (this as any)[field] = args[field];
                }
            }
        }

        this.beds = args.beds?.map((item: any) => new Bed(item)) || [];
    }
}

export class Supplier {
    @alias('id')
    public id?: number;

    @alias('name')
    public name?: string;

    @alias('biography')
    public biography?: string;

    @alias('app-url')
    public appUrl?: string;

    constructor(args: any) {
        if (!args) {
            return;
        }

        const fields: { [key: string]: string } = (<any>Supplier)._alias;

        for (const field in fields) {
            if (Object.hasOwn(fields, field)) {
                const _alias = fields[field];
                (this as any)[field] = args[_alias];
                if ((this as any)[field] === undefined) {
                    (this as any)[field] = args[field];
                }
            }
        }

        this.appUrl = getVhUrl(VhPage.SupplierDetails, { id: this.id! });
    }
}

export class CheckInInstructions {
    @alias('method')
    public method?: CheckInMethod;

    @alias('note')
    public note?: string;

    constructor(args: any) {
        if (!args) {
            return;
        }

        const fields: { [key: string]: string } = (<any>CheckInInstructions)._alias;

        for (const field in fields) {
            if (Object.hasOwn(fields, field)) {
                const _alias = fields[field];
                (this as any)[field] = args[_alias];
                if ((this as any)[field] === undefined) {
                    (this as any)[field] = args[field];
                }
            }
        }
    }
}

export class PropertyContent {
    @alias('html_description')
    public htmlDescription?: string;

    @alias('plain_text_description')
    public plainTextDescription?: string;

    @alias('summary')
    public summary?: string;

    constructor(args: any) {
        if (!args) {
            return;
        }

        const fields: { [key: string]: string } = (<any>PropertyContent)._alias;

        for (const field in fields) {
            if (Object.hasOwn(fields, field)) {
                const _alias = fields[field];
                (this as any)[field] = args[_alias];
                if ((this as any)[field] === undefined) {
                    (this as any)[field] = args[field];
                }
            }
        }
    }
}

export class PropertyDetails {
    @alias('bathrooms')
    public bathrooms?: number;

    @alias('category')
    public category?: string;

    @alias('check_in')
    public checkIn?: string;

    @alias('check_out')
    public checkOut?: string;

    @alias('currency')
    public currency?: string;

    @alias('id')
    public id?: number;

    @alias('max_occupancy')
    public maxOccupancy?: number;

    @alias('min_renter_age')
    public minRenterAge?: number;

    @alias('name')
    public name?: string;

    @alias('headline')
    public headline?: string;

    @alias('property_label')
    public propertyLabel?: string;

    @alias('rental_agreement_url')
    public rentalAgreementUrl?: string;

    @alias('square_footage')
    public squareFootage?: number;

    @alias('tourist_license')
    public touristLicense?: string;

    public contents?: PropertyContent | null;

    public amenityGroups: IAmenityGroup[] = [];

    public amenityCount: number = 0;

    public bedroomsNo: number = 0;
    public livingRoomsNo: number = 0;

    @alias('beds_no')
    public bedsNo?: number;

    public checkInInstructions?: CheckInInstructions | null;

    @alias('location')
    public location?: VhLocation;

    public images?: VhImage[];

    @alias('supplier')
    public supplier?: Supplier;

    @alias('app_url')
    public appUrl?: string;

    @alias('listing_ids')
    public listingIds: number[] = [];

    public rooms: Room[] = [];

    constructor(args: any) {
        if (!args) {
            return;
        }

        const fields: { [key: string]: string } = (<any>PropertyDetails)._alias;

        for (const field in fields) {
            if (Object.hasOwn(fields, field)) {
                const _alias = fields[field];
                (this as any)[field] = args[_alias];
                if ((this as any)[field] === undefined) {
                    (this as any)[field] = args[field];
                }
            }
        }


        this.contents = args?.contents ? new PropertyContent(args.contents) : null;
        this.checkInInstructions = args.check_in_instructions ? new CheckInInstructions(args.check_in_instructions) : null;

        const amGroups: { [name: string]: string[] } = {};

        if (args.amenities?.length) {
            for (const group of args.amenities) {
                if (group.amenities?.length) {
                    if (!amGroups[group.category]) {
                        amGroups[group.category] = [];
                    }

                    amGroups[group.category] = [...amGroups[group.category], ...group.amenities];
                    amGroups[group.category].sort((a, b) => a.localeCompare(b));
                }
            }
        }

        const groups = Object.keys(amGroups);

        for (const key of groups) {
            this.amenityGroups.push({ name: key, amenities: amGroups[key] });
            this.amenityCount += amGroups[key].length;
        }

        this.amenityGroups.sort((a, b) => {
            if (a.name.toLowerCase() === "general" && b.name.toLowerCase() !== "general") {
                return -1;
            } else if (a.name.toLowerCase() !== "general" && b.name.toLowerCase() === "general") {
                return 1;
            } else {
                return a.name.localeCompare(b.name);
            }
        });

        if (args.locations?.length) {
            this.location = new VhLocation(args.locations[0]);
        }

        if (args.images) {
            if (Array.isArray(args.images)) {
                this.images = args.images?.map((item: any) => new VhImage(item));
            } else if (typeof args.images === 'object') {
                this.images = [new VhImage(args.images)];
            } else {
                this.images = [];
            }
        } else {
            this.images = [];
        }

        this.images?.sort((a, b) => {
            if (a.primary === b.primary) {
                // If 'primary' is equal, sort by 'displaySequence'
                return (a.displaySequence ?? 0) - (b.displaySequence ?? 0);
            }

            // Since 'true' should come before 'false', invert the usual boolean comparison
            return a.primary === false ? 1 : -1;
        });

        this.supplier = args.supplier ? new Supplier(args.supplier) : undefined;

        this.bedsNo = 0;
        this.listingIds = [];

        this.rooms = args.rooms?.map((item: any) => new Room(item)) ?? [];

        this.bedroomsNo = this.rooms.filter(item => item.roomType === 'bedroom').length;
        this.livingRoomsNo = this.rooms.filter(item => item.roomType === 'living_room').length;

        args.listings?.forEach((item: { id: number }) => {
            this.listingIds.push(item.id);
        });

        args.rooms?.forEach((item: any) => {
            item.beds?.forEach((bed: any) => {
                this.bedsNo += bed.quantity ?? 0;
            });
        });

        this.appUrl = getVhUrl(VhPage.Property, { id: this.id! });
    }
}
