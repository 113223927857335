import { alias } from "../../utils/utils";

export enum ImageType {
    PROPERTY = 'PROPERTY',
    ROOM = 'ROOM'
}

export class VhImage {
    @alias('height')
    public height?: number;

    @alias('img_hash')
    public imgHash?: string;

    @alias('id')
    public id?: number;

    @alias('name')
    public name?: string;

    @alias('primary')
    public primary?: boolean;

    @alias('room_primary')
    public roomPrimary?: boolean;

    @alias('width')
    public width?: number;

    @alias('category')
    public category?: string;

    @alias('thumbnail_url')
    public thumbnailUrl?: string;

    @alias('url')
    public url?: string;

    @alias('property_id')
    public propertyId?: number;

    @alias('created_at')
    public createdAt?: string;

    @alias('display_sequence')
    public displaySequence?: number;

    @alias('updated_at')
    public updatedAt?: string;

    @alias('type')
    public type: ImageType | null = null;

    constructor(args: any) {
        if (!args) {
            return;
        }

        const fields: { [key: string]: string } = (<any>VhImage)._alias;

        for (const field in fields) {
            if (Object.hasOwn(fields, field)) {
                const _alias = fields[field];
                (this as any)[field] = args[_alias];
                if ((this as any)[field] === undefined) {
                    (this as any)[field] = args[field];
                }
            }
        }

        this.type = args['type'] ?? args['level'] ?? null;
    }
}