import { alias } from "../../utils/utils";

export class VhLocation {
    @alias('address_1')
    public address1?: string;

    @alias('address_2')
    public address2?: string;

    @alias('city')
    public city!: string;

    @alias('country')
    public country?: string;

    @alias('county')
    public county?: string;

    @alias('latitude')
    public latitude?: number;

    @alias('longitude')
    public longitude?: number;

    @alias('state')
    public state?: string;

    @alias('time_zone')
    public timeZone?: string;

    @alias('zip_code')
    public zipCode?: string;

    @alias('created_at')
    public createdAt?: string;

    @alias('id')
    public id?: number;

    @alias('property_id')
    public propertyId?: number;

    @alias('updated_at')
    public updatedAt?: string;

    constructor(args: any) {
        if (!args) {
            return;
        }

        const fields: { [key: string]: string } = (<any>VhLocation)._alias;

        for (const field in fields) {
            if (Object.hasOwn(fields, field)) {
                const _alias = fields[field];
                (this as any)[field] = args[_alias];
                if ((this as any)[field] === undefined) {
                    (this as any)[field] = args[field];
                }
            }
        }
    }
}
